import React from 'react'
import {
  useNavigate,
} from "react-router-dom";
import { Divider } from '@arco-design/web-react';
import { IconCommon, IconRobot } from '@arco-design/web-react/icon';

function NavBar() {
  let navigate = useNavigate();
  
  return (
    <div style={{
      backgroundColor: '#fff',
      borderBottom: '1px solid #e5e5e5',
      height: '60px',
      lineHeight: '60px',
      // padding: '0px 15px',
      fontWeight: 'bold',
      color: '#333',
      fontFamily: 'Microsoft YaHei',
      display: 'flex',
      alignItems: 'center',
    }}>
      <div 
        style={{fontSize: '1.5rem',marginLeft:'15px',color: 'var(--color-text-2)'}}
        onClick={() => {
          navigate("/");
        }}
      ><IconCommon /></div>
      <Divider style={{fontSize: '1.5rem',margin: '15px'}} type='vertical' />
      <div style={{display: 'flex', alignItems: 'center', color: 'var(--color-text-1)'}}>
        <IconRobot style={{fontSize: '1.5rem',marginRight: '5px', color: 'rgb(var(--arcoblue-6))'}} />
        <div style={{fontSize: '1.2rem'}}>FlightBox</div>
      </div>
    </div>
  )
}

export default NavBar