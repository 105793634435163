import React from 'react'
import {
  useNavigate,
} from "react-router-dom";
import { Card, Grid, Button } from '@arco-design/web-react';
import { IconSend } from '@arco-design/web-react/icon';

function Home() {
  const Row = Grid.Row;
  const Col = Grid.Col;
  let navigate = useNavigate();

  return (
    <div>
      <div style={{padding: '10px'}}>
        <Card style={{ maxWidth: 500, margin: '0 auto'}} bordered={false}>
          <div style={{paddingBottom: '10px'}}>小工具</div>
          <div>
            <Row>
              <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <Button 
                  type='secondary' 
                  style={{width: '100%', textAlign: 'left'}} 
                  icon={<IconSend />} 
                  size="large"
                  onClick={() => {
                    navigate("/genhomework");
                  }}
                >
                  创建作业
                </Button>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Home