import { Routes, Route, Link } from "react-router-dom";
import { Layout } from '@arco-design/web-react';

import NavBar from "./pages/NavBar";
import Home from "./pages/Home";
import GenHomeWork from "./pages/GenHomeWork";
import NotFound from "./pages/NotFound";

import logo from './logo.svg';
import './App.css';

function App() {
  const Header = Layout.Header;
  const Footer = Layout.Footer;
  const Content = Layout.Content;
  return (
    <div className="App">
      <Layout style={{ height: '100vh' }}>
        <Header><NavBar/></Header>
        <Content>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="genhomework" element={<GenHomeWork />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Content>
        <Footer>Footer</Footer>
      </Layout>
      
    </div>
  );
}

export default App;
