import React, { useState, useEffect } from 'react'
import { Card, Steps, Cascader, Tag, DatePicker, Form, Input, Button, Tabs } from '@arco-design/web-react';

const Step = Steps.Step;
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const TabPane = Tabs.TabPane;

function GenHomeWork() {
  // const [value, setValue] = useState();
  const [steps, setSteps] = useState(1);

  const [values, setValues] = React.useState();
  // useEffect(() => {
  //   // setValue(Date.now());
  //   setValueRange([Date.now(), Date.now()]);
  // }, []);
  const [form] = Form.useForm();

  const cascaderOptions = [
    {
      value: '七年级',
      label: '七年级',
      children: [
        {
          value: '（1）班',
          label: '（1）班',
        },
        {
          value: '（2）班',
          label: '（2）班',
        },
        {
          value: '（3）班',
          label: '（3）班',
        },
        {
          value: '（4）班',
          label: '（4）班',
        },
        {
          value: '（5）班',
          label: '（5）班',
        },
      ],
    },
    {
      value: '八年级',
      label: '八年级',
      children: [
        {
          value: '（1）班',
          label: '（1）班',
        },
        {
          value: '（2）班',
          label: '（2）班',
        },
        {
          value: '（3）班',
          label: '（3）班',
        },
        {
          value: '（4）班',
          label: '（4）班',
        },
        {
          value: '（5）班',
          label: '（5）班',
        },
      ],
    },
    {
      value: '九年级',
      label: '九年级',
      children: [
        {
          value: '（1）班',
          label: '（1）班',
        },
        {
          value: '（2）班',
          label: '（2）班',
        },
        {
          value: '（3）班',
          label: '（3）班',
        },
        {
          value: '（4）班',
          label: '（4）班',
        },
        {
          value: '（5）班',
          label: '（5）班',
        },
      ],
    },
    
  ];

  const subjectOptions = [
    {
      value: '语文',
      label: '语文',
    },
    {
      value: '数学',
      label: '数学',
    },
    {
      value: '英语',
      label: '英语',
    },
    {
      value: '物理',
      label: '物理',
    },
    {
      value: '化学',
      label: '化学',
    },
    {
      value: '生物',
      label: '生物',
    },
    {
      value: '政治',
      label: '政治',
    },
    {
      value: '历史',
      label: '历史',
    },
    {
      value: '地理',
      label: '地理',
    },
    {
      value: '音乐',
      label: '音乐',
    },
    {
      value: '美术',
      label: '美术',
    },
    {
      value: '体育',
      label: '体育',
    },
    {
      value: '信息技术',
      label: '信息技术',
    },
    {
      value: '其他',
      label: '其他',
    }
  ];

  return (
    <div>
      <div style={{padding: '10px'}}>
        <Card style={{ maxWidth: 500, margin: '0 auto'}} bordered={false}>
          <div style={{display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{fontSize: '1.2rem', fontWeight: 'bold'}}>发布作业</div>
              <Tag style={{marginLeft: '5px'}}>BETA</Tag>
            </div>
            <div>以 <span style={{color: 'rgb(var(--arcoblue-6))'}}>游客</span> 身份继续</div>
          </div>
        </Card>
        <div style={{padding: '5px 0'}}></div>
        <Card style={{ maxWidth: 500, margin: '0 auto'}} bordered={false} title={
          <Steps current={steps} style={{ maxWidth: 780, margin: '0 auto'}} size='small'>
           <Step title='初始化' />
           <Step title='填写信息' />
           <Step title='生成作业' />
          </Steps>
        }>
          
          <div>
            <Form 
              form={form}
              layout='vertical'
              onValuesChange={(_, values) => {
                console.log(values);
                setValues(values);
              }}
            >
              {steps === 1 ? 
                <>
                  <FormItem label='快捷操作'>
                    <Button
                      onClick={() => {
                        form.setFields({
                            data: {
                              value: [dateFormat("YYYY-mm-dd", new Date), dateFormat("YYYY-mm-dd", new Date)]
                            },
                          });
                      }}
                    >今天</Button>
                  </FormItem>
                  <FormItem label='生效范围' field='data'>
                    <DatePicker.RangePicker style={{width: '100%'}} />
                  </FormItem>
                  <FormItem label='班级选择' field='class' >
                    <Cascader showSearch placeholder='请选择' allowClear options={cascaderOptions} />
                  </FormItem>
                </>
              : ''}
              {steps === 2 ?
                <>
                  <Tabs defaultActiveTab='0' tabPosition='left' style={{ height: 400, marginBottom:'5px' }}>
                    {subjectOptions.map((item, i) => {
                      return (
                          <TabPane key={i} title={item.label}>
                            <FormItem label={<>{item.label}作业</>} field={'homework.'+item.value} >
                              <TextArea
                                placeholder='请输入作业内容（一行一项）'
                                style={{ minHeight: 358 }}
                              />
                            </FormItem>
                          </TabPane>
                      )
                    })}
                  </Tabs>
                </>
              : ''}
              {steps === 3 ?
                <>
                  <TextArea
                    placeholder='内容为空，请重试！'
                    value={
                      '作业信息 (*中文) - © FlightBox\n'+
                      '发布者：游客\t'+ dateFormat("YYYY-mm-dd HH:MM", new Date)+'\n'+
                      '=============================\n'+
                      '生效日期\t\t截止日期\t\t班级\n'+
                      values.data[0] + '\t' + values.data[1] + '\t' + values.class[0] + values.class[1] + '\n' + 
                      '\n'+
                      Object.keys(values.homework).map(item => {
                        console.log(item);
                        return '=== ' + item + ' ===\n' + values.homework[item] + '\n'
                      }).join('\n')
                    }
                    style={{ minHeight: 600 }}
                  />
                  <div style={{marginBottom: '15px'}}></div>
                </>
              : ''}
              <FormItem>
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                  <div>
                    <Button type='outline'
                      onClick={()=>{
                        setSteps(steps - 1);
                      }}
                      disabled={steps === 1}
                    >上一步</Button>
                  </div>
                  {steps !== 3 ?
                  <>
                    <div style={{textAlign: 'right'}}>
                      <Button type='outline' status='danger' 
                        onClick={() => {
                          form.clearFields();
                        }}
                      >重置</Button>
                      <span style={{padding: '0 5px'}}></span>
                      <Button type='primary'
                        onClick={()=>{
                          setSteps(steps + 1);
                        }}
                      >下一步</Button>
                    </div>
                  </> : ''}
                </div>
              </FormItem>
            </Form>

            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          </div>
        </Card>
      </div>
    </div>
  )
}

function dateFormat(fmt, date) {
  let ret;
  const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "m+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "M+": date.getMinutes().toString(),         // 分
      "S+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
  };
  return fmt;
}

export default GenHomeWork